import React from "react"
import PageTemplate from '../components/pageTemplate'
import sauces from '../data/sauces'
import ProductDisplay from '../components/product-display'
import { graphql } from 'gatsby'

export default ({data}) => (
  <PageTemplate title="Raybek Sauces" img={data.fileName}>
    <div className="text-center">
      <h5>Raybek Foods introduces the delicious NEW Home Style Sauces Range!</h5>
      <p>Selecting a great sauce has never been easier...</p>
    </div>
    <ProductDisplay products={sauces}/>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "sauces-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
