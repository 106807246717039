import garlic from '../assets/images/sauces/garlic.png'
import ginger from '../assets/images/sauces/ginger.png'
import chilli from '../assets/images/sauces/chilli.png'

let sauces = [
  {name: "Garlic Aioli", sizes: ["300g, food service sizes available upon request"]},
  {name: "Chilli Mayo", sizes: ["300g, food service sizes available upon request"]},
  {name: "Caesar Dressing", sizes: ["300g, food service sizes available upon request"]},
  {name: "Whole Egg Mayo", sizes: ["300g, food service sizes available upon request"]},
  {name: "Ranch Dressing", sizes: ["300g, food service sizes available upon request"]},
  {name: "Tartar Sauce", sizes: ["300g, food service sizes available upon request"]},
  {name: "Chipotle Mayo", sizes: ["300g, food service sizes available upon request"]},
  {name: "Crushed Chilli", image: chilli,
    ingredients: "Reconstituted Chilli, Potassium Sorbate, Sodium Benzoate & Citric Acid", sizes: ["300g, food service sizes available upon request"]
  },
  {name: "Crushed Ginger", image: ginger,
    ingredients: "Reconstituted Garlic, Reconstituted Onion & Preservative (330)", sizes: ["300g, food service sizes available upon request"]
  },
  {name: "Crushed Garlic", image: garlic,
    ingredients: "Reconstituted Garlic, Reconstituted Onion & Preservative (330)", sizes: ["300g, food service sizes available upon request"]
  },
  {name: "Japanese Mayo", sizes: ["300g, food service sizes available upon request"]}
]

export default sauces
